import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist';
import * as Sentry from "@sentry/vue";

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        app.config.globalProperties.$countryCode = import.meta.env.VITE_APP_COUNTRY;
        app.config.globalProperties.$country = import.meta.env.VITE_APP_COUNTRY === "NZ" ? "New Zealand" : "Australia";
        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_APP_ENV,
            project: import.meta.env.VITE_APP_NAME,
            release: import.meta.env.VITE_APP_VERSION,
            integrations: [
                Sentry.replayIntegration(),
            ],
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        app.use(plugin).use(ZiggyVue).mount(el);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});

